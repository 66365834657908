

.links-flexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  color: var(--accent-color);
  font-weight: bold;
}

.links-elt {
  border-bottom: 1px solid var(--accent-color);
  margin: 20px;
  display: block;
  line-height: 1.2;
}

.link-arrow-icon {
  margin-bottom: -0.1em;
}

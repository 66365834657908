.background {
  background-color: var(--background-color);
}

.sidebar {
  background-color: var(--sidebar-color);
  position: fixed;
  height: 100%;
  z-index: 1;
  bottom: 0;
  top: 0;
  left: 0;
  width: 35%;
}

.sidebar-name {
  padding: 2em;
  color: var(--text-color-full);
}

.sidebar-link {
  padding-bottom: 0.5em;
  color: var(--text-color-muted);
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 700;
  transition: width 1s;
}

.sidebar-link a {
  position: relative;
  border-bottom: none;
  color: var(--text-color-muted);
  text-decoration: none;
}

a.sidebar-link-active {
  color: var(--link-hover-color);
}

.sidebar-link a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--link-hover-color);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

a.sidebar-link-active::before {
  visibility: visible;
  transform: scaleX(1);
}

.sidebar-link a:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

.main {
  float: right;
  padding: 0em 3em;
  margin-left: 35%;
  height: 100vh;
}

/* Transform layout for mobile or thin screens */
@media screen and (max-width: 900px) {
  .sidebar {
    position: static;
    width: 100%;
  }

  .main {
    margin-left: auto;
    height: auto;
    padding: 1em 1em;
  }
}

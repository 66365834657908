.research-container {
  margin: 3em 0em;
}

.publication-section-container {
  text-align: left;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.publication-section-container h2, h3, h4, h5, h6 {
  margin: 0.5em 0em;
  font-weight: normal;
}

.project-sections-header {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--button-color);
  border-radius: 5px;
  padding: 10px;
}

.project-sections-header-label {
  margin-right: 1em;
  font-weight: bold;
}

.section-title {
  text-align: left;
  color: var(--text-color-full);
  font-size: 1.5em;
  margin: 0.5em 0em;
}

.project-title {
  color: var(--text-color-full);
}

.project-abstract {
  margin: 0.5em 0em;
}

.project-links-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin: 0.5em 0em;
}

.project-links-container a {
  color: var(--text-color-muted);
}

.project-link-elt {
  display: flex;
  background-color: var(--button-color);
  border-radius: 5px;
  margin: 1em 1em 1em 0em;
  padding: 10px;
}

.project-link-elt .icon {
  margin-right: 0.5em;
}

.project-picture, .project-video {
  text-align: center
}

.project-picture img, video {
  width: 75%;
  margin: 0.5em 0em;
}

.spacer {
  height: 1em;
}

/* Load some fonts */
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,700');

.App {
  text-align: center;
}

:root {
  --background-color: #FFF;
  --sidebar-color: rgba(0, 0, 0, 0.04);
  --text-color-muted: rgba(0, 0, 0, 0.6);
  --link-hover-color: rgba(0, 0, 0, 0.9);
  --text-color-full: rgba(0, 0, 0, 1);
  --button-color: rgba(0, 0, 0, 0.1);
  /* --accent-color: rgb(79, 6, 189); */
  --accent-color: #518fbb;
}

body {
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.8;
  color: var(--text-color-muted);
  font-size: 18px;
}

a {
  color: var(--accent-color);
  text-decoration: none;
}

a:hover {
  color: var(--link-hover-color);
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
}

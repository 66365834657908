@import url(https://fonts.googleapis.com/css?family=Montserrat:300,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background {
  background-color: var(--background-color);
}

.sidebar {
  background-color: var(--sidebar-color);
  position: fixed;
  height: 100%;
  z-index: 1;
  bottom: 0;
  top: 0;
  left: 0;
  width: 35%;
}

.sidebar-name {
  padding: 2em;
  color: var(--text-color-full);
}

.sidebar-link {
  padding-bottom: 0.5em;
  color: var(--text-color-muted);
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 700;
  transition: width 1s;
}

.sidebar-link a {
  position: relative;
  border-bottom: none;
  color: var(--text-color-muted);
  text-decoration: none;
}

a.sidebar-link-active {
  color: var(--link-hover-color);
}

.sidebar-link a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--link-hover-color);
  visibility: hidden;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

a.sidebar-link-active::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.sidebar-link a:hover::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.main {
  float: right;
  padding: 0em 3em;
  margin-left: 35%;
  height: 100vh;
}

/* Transform layout for mobile or thin screens */
@media screen and (max-width: 900px) {
  .sidebar {
    position: static;
    width: 100%;
  }

  .main {
    margin-left: auto;
    height: auto;
    padding: 1em 1em;
  }
}

/* Load some fonts */

.App {
  text-align: center;
}

:root {
  --background-color: #FFF;
  --sidebar-color: rgba(0, 0, 0, 0.04);
  --text-color-muted: rgba(0, 0, 0, 0.6);
  --link-hover-color: rgba(0, 0, 0, 0.9);
  --text-color-full: rgba(0, 0, 0, 1);
  --button-color: rgba(0, 0, 0, 0.1);
  /* --accent-color: rgb(79, 6, 189); */
  --accent-color: #518fbb;
}

body {
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.6);
  color: var(--text-color-muted);
  font-size: 18px;
}

a {
  color: #518fbb;
  color: var(--accent-color);
  text-decoration: none;
}

a:hover {
  color: rgba(0, 0, 0, 0.9);
  color: var(--link-hover-color);
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
}

.circular-img {
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

ul {
  text-align: left
}

.me-pic-container {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

/* 
 * This keeps a 1:1 aspect ratio for some reason...
 * https://stackoverflow.com/questions/18946564/how-to-make-height-equal-width-with-css
 */ 
.me-pic-container:before {
  content: "";
  display: block;
  padding-top: 100%;
}



.links-flexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  color: var(--accent-color);
  font-weight: bold;
}

.links-elt {
  border-bottom: 1px solid var(--accent-color);
  margin: 20px;
  display: block;
  line-height: 1.2;
}

.link-arrow-icon {
  margin-bottom: -0.1em;
}

.research-container {
  margin: 3em 0em;
}

.publication-section-container {
  text-align: left;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.publication-section-container h2, h3, h4, h5, h6 {
  margin: 0.5em 0em;
  font-weight: normal;
}

.project-sections-header {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--button-color);
  border-radius: 5px;
  padding: 10px;
}

.project-sections-header-label {
  margin-right: 1em;
  font-weight: bold;
}

.section-title {
  text-align: left;
  color: var(--text-color-full);
  font-size: 1.5em;
  margin: 0.5em 0em;
}

.project-title {
  color: var(--text-color-full);
}

.project-abstract {
  margin: 0.5em 0em;
}

.project-links-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin: 0.5em 0em;
}

.project-links-container a {
  color: var(--text-color-muted);
}

.project-link-elt {
  display: flex;
  background-color: var(--button-color);
  border-radius: 5px;
  margin: 1em 1em 1em 0em;
  padding: 10px;
}

.project-link-elt .icon {
  margin-right: 0.5em;
}

.project-picture, .project-video {
  text-align: center
}

.project-picture img, video {
  width: 75%;
  margin: 0.5em 0em;
}

.spacer {
  height: 1em;
}

p {
  margin: 2em;
}

.personal-pic-container {
  width: 200px;
  height: 200px;
  margin: 1em;
}

.louie {
  float: left;
}

.brassrat {
  float: right;
}

